import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RouterService } from '@shared/services/router.service';
import { LoaderService } from '@shared/services/loader.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
      transition(':leave', [animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class LoginContainerComponent implements OnInit {
  loginLoading$: Observable<boolean>;
  currentPath$: Observable<any>;

  constructor(private loadingService: LoaderService, private routerService: RouterService) {}

  ngOnInit(): void {
    this.currentPath$ = this.routerService.getCurrentRoute();
    this.loginLoading$ = this.loadingService.getLoading();
  }
}
