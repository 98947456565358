import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@shared/models/user';
import { LoginService } from '@features/login/services/login.service';
import { Observable } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('active', style({ 'overflow-y': 'hidden', opacity: 1, position: 'relative', 'z-index': 1, transform: 'translateX(0)' })),
      state('inactive', style({ 'overflow-y': 'hidden', opacity: 0, transform: 'translateX(100%)' })),
      transition('inactive => active', [
        style({ height: '*', transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms ease-in-out', style({ height: '*', transform: 'translateX(0)', opacity: 1, overflow: 'hidden' })),
      ]),
      transition('active => inactive', [
        style({ height: '*', transform: 'translateX(0%)', opacity: 1 }),
        animate('280ms ease-in-out', style({ height: '*', transform: 'translateX(-100%)', opacity: 0, overflow: 'hidden' })),
      ]),
    ]),
  ],
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  user: User;
  duplicateEmail: string;
  @ViewChild('username') usernameInputField: ElementRef;
  emailDuplicate$: Observable<boolean>;

  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
    this.emailDuplicate$ = this.loginService.getEmailDuplicationCheck();
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      username_confirm: new FormControl(),
    });
    this.loginService.removeDuplicateWarning();
  }

  ngAfterViewInit() {
    this.emailDuplicate$.subscribe((res) => {
      if (res) {
        this.duplicateEmail = this.loginForm.value.email;
        this.usernameInputField.nativeElement.focus();
      }
    });
  }

  refresh(): void {
    window.location.reload();
  }

  submitLogin() {
    let user: User = {
      email: this.loginForm.value.email.trim(),
      password: this.loginForm.value.password,
    };

    if (!!this.duplicateEmail) {
      user.username = this.loginForm.value.username_confirm.trim();
      user.email = this.duplicateEmail.trim();
    }
    this.loginForm.markAllAsTouched();

    if (this.loginForm.valid) {
      this.loginService.login(user);
    }
  }
}
