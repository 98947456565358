import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@shared/models/user';
import { Api } from './api.service';
import { LoginResponse } from '@shared/models/login-response';
import { StartSession } from '@shared/models/start-session';

@Injectable({
  providedIn: 'root',
})
export class LoginApiService {
  constructor(private api: Api) {}

  login(user: User): Observable<LoginResponse> {
    return this.api.post('v1/users/authenticate', {
      email: user.email,
      password: user.password,
      username: user.username,
    });
  }

  startSession(params: StartSession): Observable<any> {
    return this.api.post('v1/users/start_session', params);
  }

  userConfirmEmail(token: string): Observable<any> {
    return this.api.post('v1/users/confirm_email', { token: token });
  }

  forgotPassword(user: User): Observable<any> {
    return this.api.post('v1/users/forgot_password', {
      username: user.username,
      email: user.email,
    });
  }

  setPassword(token: string, password: string): Observable<any> {
    return this.api.post('v1/users/set_password', {
      token: token,
      password: password,
    });
  }

  groupConfirmEmail(token: string): Observable<any> {
    return this.api.post('v1/groups/confirm_email', {
      token: token,
    });
  }
}
