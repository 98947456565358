import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoginService } from '@features/login/services/login.service';

@Component({
  selector: 'app-partner-agreement',
  templateUrl: './partner-agreement.component.html',
  styleUrls: ['./partner-agreement.component.scss'],
})
export class PartnerAgreementComponent implements OnInit {
  partnerAgreementUrl: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer, private loginService: LoginService) {}

  ngOnInit(): void {
    this.partnerAgreementUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('https://cdn-uploads.eset.co.za/ESET Partner Agreement.pdf#toolbar=0&view=Fit');
  }

  acceptPartnerAgreement() {
    this.loginService.startSession({ accepted_partner_agreement: true });
  }
}
