import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as routeReducers from '../../reducers';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(private store: Store) {}

  getRouteData(): Observable<any> {
    return this.store.select(routeReducers.selectRouteData);
  }

  getCurrentRoute(): Observable<any> {
    return this.store.select(routeReducers.selectCurrentRoute);
  }
}
