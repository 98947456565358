import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectLoading } from '@shared/reducers/loading.reducer';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor(private store: Store) {}

  getLoading(): Observable<boolean> {
    return this.store.select(selectLoading);
  }
}
