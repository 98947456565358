<ion-icon class="back-btn" name="arrow-back-circle" routerLink="/"></ion-icon>
<p class="text-center py-2">Authenticator Code</p>
<form [formGroup]="mfaForm">
  <ng-otp-input
    (onInputChange)="submitMfaOtp($event)"
    (change)="submitMfaOtpAutofill($event)"
    [config]="{
      allowNumbersOnly: true,
      length: 6,
      placeholder: '0',
      inputClass: 'border-2 focus:border-primary h-[60px] placeholder-grey-concrete'
    }"
  >
  </ng-otp-input>
</form>
<div class="text-center py-2 font-normal">
  <div>Having problems?</div>
  <a routerLink="/mfa-recovery" id="mfa-recovery">Enter a recovery code</a>
</div>
