<div
  [ngClass]="{
    'min-h-[400px] min-w-[375px] mb-3 bg-grey-light-concrete h-min rounded-xl p-7 sm:p-7 relative overflow-hidden transition-all':
      (currentPath$ | async)?.data?.title !== 'Email confirmation',
    'p-1': (currentPath$ | async)?.data?.title === 'Accept Partner Terms'
  }"
>
  <div class="h-full" [ngClass]="{ 'opacity-50 pointer-events-none select-none cursor-wait': loginLoading$ | async }">
    <router-outlet></router-outlet>
  </div>
  <ion-progress-bar type="indeterminate" *ngIf="loginLoading$ | async" class="absolute left-0 bottom-0" [@fadeInOut]> </ion-progress-bar>
</div>
