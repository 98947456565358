<form [formGroup]="loginForm" class="relative overflow-visible pt-2 w-[345px]">
  <div class="flex flex-row h-[215px]">
    <div [@slideInOut]="(emailDuplicate$ | async) ? 'inactive' : 'active'" class="w-[345px] absolute">
      <div class="flex-row py-1 mb-2 relative">
        <label class="block py-2 text-grey-blue">{{ (emailDuplicate$ | async) ? 'Username' : 'Email Address' }}</label>
        <input
          #email
          autocomplete="username"
          id="email"
          type="text"
          formControlName="email"
          (focus)="loginForm.controls['email'].markAsUntouched()"
          autofocus
          [ngClass]="{ 'border-bright-crimson text-bright-crimson': loginForm.controls['email'].errors && loginForm.controls['email'].touched }"
        />
        <div
          class="text-bright-crimson text-right text-sm py-2 right-0 absolute"
          [hidden]="!(loginForm.controls['email'].errors && loginForm.controls['email'].touched)"
        >
          <div id="required" class="error" *ngIf="loginForm.controls['email'].errors?.['required']">
            {{ (emailDuplicate$ | async) ? 'Username' : 'Email Address' }} is required
          </div>
        </div>
      </div>
      <div class="flex-row py-1 mb-8 relative">
        <label class="block py-2 text-grey-blue">Password</label>
        <input
          autocomplete="current-password"
          id="password"
          type="password"
          formControlName="password"
          (focus)="loginForm.controls['password'].markAsUntouched()"
          [ngClass]="{ 'border-bright-crimson text-bright-crimson': loginForm.controls['password'].errors && loginForm.controls['password'].touched }"
          (keyup.enter)="submitLogin()"
        />
        <div
          class="text-bright-crimson text-sm py-2 absolute right-0"
          [hidden]="!(loginForm.controls['password'].errors && loginForm.controls['password'].touched)"
        >
          <div id="required" class="error" *ngIf="loginForm.controls['password'].errors?.['required']">Password is required</div>
        </div>
      </div>
    </div>
    <div [@slideInOut]="(emailDuplicate$ | async) ? 'active' : 'inactive'" class="w-[345px] h-60 absolute">
      <div class="flex items-center justify-between">
        <ion-icon class="back-btn" name="arrow-back-circle" (click)="refresh()"></ion-icon>
        <div class="bg-white rounded-2xl px-3 py-1.5 text-sm">
          <span>{{ duplicateEmail }}</span>
        </div>
        <div class="w-8"></div>
      </div>

      <div id="email-duplicate-warning" class="bg-bright-orange text-bright-orange text-sm rounded p-2 flex items-center bg-opacity-20 mt-3">
        <div>
          <ion-icon class="text-3xl mr-2" name="warning"></ion-icon>
        </div>
        <div>
          <span class="font-normal"> Duplicate email. Please use the username for the account you wish sign in to. </span>
        </div>
      </div>
      <div class="flex-row py-1 mb-2 relative">
        <label class="block py-2 text-grey-blue">Username</label>
        <input
          #username
          id="username_confirm"
          autocomplete="doNotAutocomplete"
          type="text"
          formControlName="username_confirm"
          (focus)="loginForm.controls['username_confirm'].markAsUntouched()"
          autofocus
          [ngClass]="{
            'border-bright-crimson text-bright-crimson': loginForm.controls['username_confirm'].errors && loginForm.controls['username_confirm'].touched
          }"
        />
        <div
          class="text-bright-crimson text-right text-sm py-2 right-0 absolute"
          [hidden]="!(loginForm.controls['username_confirm'].errors && loginForm.controls['username_confirm'].touched)"
        >
          <div id="required" class="error" *ngIf="loginForm.controls['username_confirm'].errors?.['required']">Username is required</div>
        </div>
      </div>
    </div>
  </div>
</form>
<button id="submit" (click)="submitLogin()" class="my-2 block w-full">Continue</button>
<a id="forgot-password" routerLink="/forgot-password" class="mt-4 block text-primary text-center font-medium w-full">Forgot Password? </a>
