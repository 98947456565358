import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LoginService } from '@features/login/services/login.service';
@Component({
  selector: 'app-mfa-otp',
  templateUrl: './mfa-otp.component.html',
  styleUrls: ['./mfa-otp.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MfaOtpComponent implements OnInit {
  input: string = '';
  mfaForm: FormGroup;

  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
    this.mfaForm = new FormGroup({
      input: new FormControl(''),
    });
  }

  submitMfaOtpAutofill(event: any) {
    this.input += event.target.value;
    this.submitMfaOtp(this.input);
  }

  submitMfaOtp(input: string) {
    if (input.length === 6) {
      this.loginService.startSession({ mfa_otp: input });
    }
  }
}
