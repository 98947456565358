import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setPassword, setPasswordFailure, setPasswordSuccess } from '@features/set-password/actions/set-password.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { LoginApiService } from '@core/services/login-api.service';
import { Router } from '@angular/router';
import { ToastService } from '@shared/services/toast.service';

@Injectable()
export class SetPasswordEffects {
  constructor(private actions$: Actions, private loginApiService: LoginApiService, private router: Router, private toastService: ToastService) {}

  setPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPassword),
      switchMap((action) => {
        return this.loginApiService.setPassword(action.token, action.password).pipe(
          map(() => {
            return setPasswordSuccess();
          }),
          catchError((error) => {
            return of(setPasswordFailure({ error }));
          })
        );
      })
    )
  );

  setPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPasswordSuccess),
        tap(() => {
          this.toastService.showToast('success', 'Password has been set. You may now login');
          this.router.navigate(['']);
        })
      ),
    { dispatch: false }
  );

  setPasswordFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPasswordFailure),
        tap((action) => {
          const message = action.error.error.errors;
          this.toastService.showToast('error', message);
        })
      ),
    { dispatch: false }
  );
}
