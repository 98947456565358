import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '@features/login/services/login.service';

@Component({
  selector: 'app-mfa-recovery',
  templateUrl: './mfa-recovery.component.html',
  styleUrls: ['./mfa-recovery.component.scss'],
})
export class MfaRecoveryComponent implements OnInit {
  mfaRecoveryForm: FormGroup;

  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
    this.mfaRecoveryForm = new FormGroup({
      recovery_code: new FormControl('', Validators.required),
    });
  }

  recoveryCodeLogin() {
    this.mfaRecoveryForm.markAllAsTouched();
    let code = this.mfaRecoveryForm.value.recovery_code.trim() as string;
    if (this.mfaRecoveryForm.valid) {
      this.loginService.startSession({ recovery_code: code });
    }
  }
}
