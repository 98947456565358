import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root',
})
export class MfaOtpGuard implements CanActivate {
  isMfaActive: boolean;
  hasAccessToken: boolean;

  constructor(private loginService: LoginService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.loginService.getAccessToken().pipe(
      withLatestFrom(this.loginService.getMfaActive()),
      switchMap(([accessToken, mfaActive]) => {
        return !!accessToken && mfaActive ? of(true) : of(this.router.parseUrl('/'));
      })
    );
  }
}
