<lib-version-update [swUpdateInterval]="600000"></lib-version-update>
<lib-maintenance></lib-maintenance>
<lib-offline></lib-offline>
<div class="flex flex-col h-screen bg-white overflow-y-auto">
  <div class="m-auto w-full flex-row align-center justify-center">
    <app-header class="w-full"></app-header>
    <app-login-container class="m-auto flex justify-center"></app-login-container>
    <app-footer class="text-center text-xs text-grey-mid my-2"></app-footer>
  </div>
</div>
