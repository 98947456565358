import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { User } from '@shared/models/user';
import * as fromLogin from '@features/login/reducers/login.reducer';
import { login, removeDuplicateWarning } from '@features/login/actions/login.actions';
import { startSession } from '@features/login/actions/start-session.actions';
import { StartSession } from '@shared/models/start-session';
import { setPassword } from '@features/set-password/actions/set-password.actions';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private store: Store) {}

  login(user: User): void {
    this.getEmailDuplicationCheck()
      .pipe(take(1))
      .subscribe((res) => {
        if (!res) {
          delete user.username;
        }
      });
    this.store.dispatch(login({ user: user }));
  }

  startSession(session: StartSession): void {
    this.store.dispatch(startSession({ data: session }));
  }

  setPassword(token: string, password: string): void {
    this.store.dispatch(setPassword({ token: token, password: password }));
  }

  removeDuplicateWarning(): void {
    this.store.dispatch(removeDuplicateWarning());
  }

  getAccessToken(): Observable<string> {
    return this.store.select(fromLogin.selectAccessToken);
  }

  getMfaActive(): Observable<boolean> {
    return this.store.select(fromLogin.selectMfaActive);
  }

  getEmailDuplicationCheck(): Observable<boolean> {
    return this.store.select(fromLogin.selectEmailDuplicationCheck);
  }
  getLoginLoading(): Observable<boolean> {
    return this.store.select(fromLogin.selectLoginLoading);
  }
}
