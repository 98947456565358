import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { startSession, startSessionFailure, startSessionSuccess } from '@features/login/actions/start-session.actions';
import { LoginService } from '@features/login/services/login.service';
import { LoginApiService } from '@core/services/login-api.service';
import { environment } from '@environments/environment';
import { ToastService } from '@shared/services/toast.service';

@Injectable()
export class StartSessionEffects {
  constructor(private actions$: Actions, private loginService: LoginService, private loginApiService: LoginApiService, private toastService: ToastService) {}

  startSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startSession),
      withLatestFrom(this.loginService.getAccessToken()),
      switchMap(([action, accessToken]) => {
        const sessionData = { ...action.data, access_token: accessToken };
        return this.loginApiService.startSession(sessionData).pipe(
          map(() => {
            return startSessionSuccess();
          }),
          catchError((error) => {
            return of(startSessionFailure({ error }));
          })
        );
      })
    )
  );

  startSessionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startSessionSuccess),
        tap((action) => {
          const url = environment.transact_url;
          console.info('New session, redirecting...');
          window.location.href = url;
        })
      ),
    { dispatch: false }
  );

  startSessionFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startSessionFailure),
        tap((action) => {
          const message = action.error.error.errors;
          this.toastService.showToast('error', message, { icon: 'alert-circle' });
        })
      ),
    { dispatch: false }
  );
}
