<ion-icon class="back-btn" name="arrow-back-circle" routerLink="/"></ion-icon>
<form [formGroup]="mfaRecoveryForm" class="relative overflow-visible">
  <div class="flex-row py-1 mb-8 relative">
    <label class="block py-2 text-grey-blue">Recovery Code</label>
    <input
      type="text"
      formControlName="recovery_code"
      (focus)="mfaRecoveryForm.controls['recovery_code'].markAsUntouched()"
      autofocus
      [ngClass]="{
        'border-bright-crimson text-bright-crimson': mfaRecoveryForm.controls['recovery_code'].errors && mfaRecoveryForm.controls['recovery_code'].touched
      }"
    />
    <div
      class="text-bright-crimson text-right text-sm py-2 right-0 absolute"
      [hidden]="!(mfaRecoveryForm.controls['recovery_code'].errors && mfaRecoveryForm.controls['recovery_code'].touched)"
    >
      <div id="required" class="error" *ngIf="mfaRecoveryForm.controls['recovery_code'].errors?.['required']">Recovery code is required</div>
    </div>
  </div>
</form>
<button (click)="recoveryCodeLogin()" class="my-2 block w-full">Proceed</button>
