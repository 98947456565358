import { createAction, props } from '@ngrx/store';
import { User } from '@shared/models/user';

export const forgotPassword = createAction('[ForgotPassword] forgotPassword', props<{ user: User; isRetry?: boolean }>());

export const forgotPasswordSuccess = createAction('[ForgotPassword] forgotPassword Success', props<{ user: User; isRetry?: boolean }>());

export const forgotPasswordFailure = createAction('[ForgotPassword] forgotPassword Failure', props<{ error: any }>());

export const removeDuplicateWarning = createAction('[ForgotPassword] Do Remove Duplicate Email Warning');
