import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { forgotPassword, forgotPasswordFailure, forgotPasswordSuccess } from '@features/forgot-password/actions/forgot-password.actions';
import { setPassword, setPasswordFailure, setPasswordSuccess } from '@features/set-password/actions/set-password.actions';
import { confirmEmail, confirmEmailFailure, confirmEmailSuccess } from '@features/confirm-email/actions/confirm-email.actions';
import { login, loginFailed, loginSuccess } from '@features/login/actions/login.actions';
import { startSession, startSessionFailure, startSessionSuccess } from '@features/login/actions/start-session.actions';

export const featureKey = 'loading';
export interface State {
  isLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
};

export const loadingReducer = createReducer(
  initialState,
  on(forgotPassword, setPassword, confirmEmail, login, startSession, (state, action) => {
    return { ...state, isLoading: true };
  }),
  on(forgotPasswordSuccess, setPasswordSuccess, confirmEmailSuccess, loginSuccess, startSessionSuccess, (state, action) => {
    return { ...state, isLoading: false };
  }),
  on(forgotPasswordFailure, setPasswordFailure, confirmEmailFailure, loginFailed, startSessionFailure, (state, action) => {
    return { ...state, isLoading: false };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return loadingReducer(state, action);
}

export const loadingFeatureSelector = createFeatureSelector<State>(featureKey);

export const selectLoading = createSelector(loadingFeatureSelector, (state) => state.isLoading);
