import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginRoutingModule } from '@features/login/login-routing.module';
import { AppRoutingModule } from '../app-routing.module';
import { LoginContainerComponent } from './containers/login-container/login-container.component';
import { IonicModule } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';
import * as fromLoading from '@shared/reducers/loading.reducer';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, LoginContainerComponent],
  imports: [CommonModule, LoginRoutingModule, AppRoutingModule, IonicModule, StoreModule.forFeature(fromLoading.featureKey, fromLoading.reducer)],
  exports: [HeaderComponent, FooterComponent, LoginContainerComponent],
})
export class SharedModule {}
