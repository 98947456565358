import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MfaOtpComponent } from './components/mfa-otp/mfa-otp.component';
import { MfaRecoveryComponent } from './components/mfa-recovery/mfa-recovery.component';
import { PartnerAgreementComponent } from './components/partner-agreement/partner-agreement.component';
import { MfaOtpGuard } from './guards/mfa-otp.guard';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    title: 'Account Login',
    data: {
      title: 'Account Login',
      subtitle: 'Sign in to continue',
    },
  },
  {
    path: 'mfa-otp',
    component: MfaOtpComponent,
    title: 'Account Login',
    data: {
      title: 'Account Login',
      subtitle: 'Enter a verification code',
    },
    canActivate: [MfaOtpGuard],
  },
  {
    path: 'mfa-recovery',
    component: MfaRecoveryComponent,
    title: 'Multi-factor Recovery',
    data: {
      title: 'Multi-factor Recovery',
      subtitle: 'Enter one of your recovery codes below to proceed',
    },
    canActivate: [MfaOtpGuard],
  },
  {
    path: 'partner-agreement',
    component: PartnerAgreementComponent,
    title: 'Accept Partner Terms',
    data: {
      title: 'Accept Partner Terms',
      subtitle: 'Read and accept partner agreement below to proceed',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
