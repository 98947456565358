import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GravatarModule } from 'ngx-gravatar';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './components/login/login.component';
import { MfaOtpComponent } from './components/mfa-otp/mfa-otp.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { MfaRecoveryComponent } from './components/mfa-recovery/mfa-recovery.component';
import { PartnerAgreementComponent } from './components/partner-agreement/partner-agreement.component';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from '@features/login/effects/login.effects';
import { StartSessionEffects } from './effects/start-session.effects';
import { StoreModule } from '@ngrx/store';
import * as fromLogin from './reducers/login.reducer';
import { ForgotPasswordEffects } from '../forgot-password/effects/forgot-password.effects';
import { SetPasswordEffects } from '../set-password/effects/set-password.effects';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [LoginComponent, MfaOtpComponent, MfaRecoveryComponent, PartnerAgreementComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    GravatarModule,
    StoreModule.forFeature(fromLogin.featureKey, fromLogin.reducer),
    NgOtpInputModule,
    EffectsModule.forFeature([LoginEffects, StartSessionEffects, ForgotPasswordEffects, SetPasswordEffects]),
    IonicModule,
  ],
})
export class LoginModule {}
