import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(public toastController: ToastController) {}

  async showToast(toastType: string, message: string, options?: object) {
    type iconMap = {
      [key: string]: string;
    };
    const errorIcons: iconMap = {
      warning: 'warning',
      error: 'alert-circle',
      info: 'information-circle',
      success: 'checkmark-circle',
    };
    console.log(toastType);
    let defaultOption: ToastOptions = {
      message: message,
      position: 'top',
      duration: 3000,
      mode: 'ios',
      icon: errorIcons[toastType],
      cssClass: ['toast-alignment', toastType + '-toast'],
      buttons: [
        {
          icon: 'close-circle-outline',
          side: 'end',
          role: 'cancel',
        },
      ],
    };
    let option: ToastOptions = { ...defaultOption, ...options };
    const toast = await this.toastController.create(option);
    toast.present();
  }
}
