import { createAction, props } from '@ngrx/store';
import { User } from '@shared/models/user';
import { LoginResponse } from '@shared/models/login-response';

export const login = createAction('[Login] Do Login', props<{ user: User }>());

export const loginSuccess = createAction('[Login] Do Login Success', props<{ data: LoginResponse }>());

export const removeDuplicateWarning = createAction('[Login] Do Remove Duplicate Email Warning');

export const loginFailed = createAction('[Login] Do Login Failed', props<{ error: any }>());
